import React, {useEffect, useState} from 'react';
import SponsorWidget from "../components/SponsorWidget";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import '../i18n';
import SolutionsWidget from "../components/SolutionsWidget";
import ContactInfoCard from "../components/ContactInfoCard";


const TaskBoard = () => {

    const serviceKeys = [
        "webMigration",
        "aiImplementation",
        "customDevelopment",
        "platformIntegrations",
        "softwareTesting",
        "processDigitalization",
        "projectManagement",
        "customCode",
        "other"
    ];

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const handleContactButtonClick = () => {
        navigate('/contact'); // Simplemente navega a Taskboard
    };

    const [submitMessage, setSubmitMessage] = useState(''); // Estado para el mensaje
    const [isError, setIsError] = useState(false); // Estado para indicar si hubo un error

    const [formData, setFormData] = useState({
        formType: 'taskBoard', // Identificador del formulario
        name: '',
        email: '',
        phone: '',
        projectName: '',
        description: '',
        estimatedHours: '',
        budget: '',
        notes: '',
        technologies: [],
        services:[]
    });

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;

        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: checked
                    ? [...prevState[name], value] // Add item to the array
                    : prevState[name].filter((item) => item !== value) // Remove item from the array
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/mail.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), // Enviar los datos del formulario
            });

            if (response.ok) {
                setSubmitMessage(t("successTaskSubmit")); // Mensaje de éxito
                setIsError(false); // No es un error
                setFormData({
                    formType: 'taskBoard', // Asegúrate de incluir el identificador
                    name: '',
                    email: '',
                    phone: '',
                    projectName: '',
                    description: '',
                    estimatedHours: '',
                    budget: '',
                    notes: '',
                    technologies: [],
                    services: []

                });
            } else {
                setSubmitMessage(t("errorTaskSubmit")); // Mensaje de error
                setIsError(true);
            }
        } catch (error) {
            console.error('Error submitting the task:', error);
            setSubmitMessage(t("errorMessage")); // Mensaje de error
            setIsError(true);
        }
    };


    const technologiesList = [
        'JavaScript',
        'WordPress',
        'PHP Custom Code',
        'Laravel',
        'Front-End',
        'Backend',
        'Python',
        'Progressive Apps',
        "Other"
    ];

    return (
        <div>
            <div className="grow md:flex space-y-8  md:space-y-0 md:space-x-8 pb-16 md:pb-20">
                <div className="grow">
                    <div className="max-w-[700px]">
                        <h1 className="h1 font-aspekta py-4">{t("taskboardTitle")}</h1>
                        <div className="text-slate-500 dark:text-slate-400 space-y-8">
                            <div className="space-y-10 py-4">

                                <p>{t("taskboardText")}</p>
                            </div>
                            <form
                                onSubmit={handleSubmit}
                                action="#"
                                method="POST"
                                className="max-w-xl mx-auto mt-16 sm:mt-20"
                            >
                                <h2 className="text-xl font-500 leading-6 text-gray-900">{t("projectFormTitle")}</h2>

                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mt-6">
                                    {/* Full Name */}
                                    <div>
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="name">
                                            <span className="required">*</span>{t("projectFormName")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div>
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="email">
                                            <span className="required">*</span>{t("projectFormEmail")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Phone */}
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="phone">
                                            {t("projectFormPhone")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    {/* Project Name */}
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="projectName">
                                            <span className="required">*</span>{t("projectFormProjectTaskName")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="projectName"
                                                name="projectName"
                                                type="text"
                                                value={formData.projectName}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/*Services*/}
                                    <div className="sm:col-span-2">
                                        <fieldset>
                                            <legend className="block text-sm leading-6 text-gray-900 mb-4">
                                                <span className="required">*</span> {t("selectServices")}
                                            </legend>
                                            <div className="mt-2.5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                                                {serviceKeys.map((key) => (
                                                    <div key={key} className="flex items-center">
                                                        <input
                                                            id={key}
                                                            name="services"
                                                            type="checkbox"
                                                            value={key}
                                                            checked={formData.services?.includes(key)}
                                                            onChange={handleCheckboxChange}
                                                            className="h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
                                                        />
                                                        <label htmlFor={key} className="ml-3 block text-sm text-gray-900">
                                                            {t(`services.${key}`)}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>

                                    {/* Technologies */}
                                    <div className="sm:col-span-2">
                                        <fieldset>
                                            <legend className="block text-sm leading-6 text-gray-900 mb-4"><span
                                                className="required">*</span>{t("projectFormTechnologies")}</legend>
                                            <div
                                                className="mt-2.5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                                                {technologiesList.map((tech) => (
                                                    <div key={tech} className="flex items-center">
                                                        <input
                                                            id={tech}
                                                            name="technologies"
                                                            type="checkbox"
                                                            value={tech}
                                                            checked={formData.technologies.includes(tech)}
                                                            onChange={handleCheckboxChange}
                                                            className="h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
                                                        />
                                                        <label htmlFor={tech}
                                                               className="ml-3 block text-sm text-gray-900">
                                                            {tech}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>

                                    {/* Description */}
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="description">
                                            {t("projectFormDescription")}
                                        </label>
                                        <div className="mt-2.5">
        <textarea
            id="description"
            name="description"
            rows={4}
            value={formData.description}
            onChange={handleChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
            required
        />
                                        </div>
                                    </div>

                                    {/* Estimated Hours */}
                                    <div>
                                        <label className="block text-sm leading-6 text-gray-900"
                                               htmlFor="estimatedHours">
                                            {t("projectFormEstimatedHours")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="estimatedHours"
                                                name="estimatedHours"
                                                type="number"
                                                value={formData.estimatedHours}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Budget */}
                                    <div>
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="budget">
                                            {t("projectFormBudget")}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                id="budget"
                                                name="budget"
                                                type="number"
                                                value={formData.budget}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Additional Notes */}
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm leading-6 text-gray-900" htmlFor="notes">
                                            {t("projectFormAditionalNotes")}
                                        </label>
                                        <div className="mt-2.5">
        <textarea
            id="notes"
            name="notes"
            rows={4}
            value={formData.notes}
            onChange={handleChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6"
        />
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="mt-10">
                                    <button
                                        type="submit"
                                        className="block w-full rounded-md bg-sky-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus:ring-sky-500"
                                    >
                                        {t("projectFormSubmit")}
                                    </button>
                                    {submitMessage && (
                                        <p className={`mt-4 message ${isError ? 'message-error' : 'message-success'}`}>
                                            {submitMessage}
                                        </p>
                                    )}
                                </div>
                            </form>


                        </div>
                    </div>
                </div>

                <aside className="md:w-[240px] lg:w-[300px] shrink-0">


                    <div className="space-y-6 mb-6">
                        {/* Sponsor widget */}
                        <SponsorWidget/>
                    </div>

                    { /*Popular services widget*/}
                    <SolutionsWidget />

                    {/*Contact information*/}
                    <ContactInfoCard/>
                    <div
                        className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5 mt-6">

                        <p className="font-aspekta   text-sm inline-flex  py-4">{t("moreInfo")}</p>

                        <button
                            onClick={handleContactButtonClick}
                            className="btn-sm w-full text-slate-100 bg-sky-500 hover:bg-sky-600"
                        >
                            {t("contactBtn")}
                        </button>
                    </div>
                </aside>
            </div>
        </div>

    );
};

export default TaskBoard;
