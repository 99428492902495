import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import '../i18n';


const TaskMe = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const handleButtonClick = () => {
        navigate('/taskboard'); // Simplemente navega a Taskboard
    };

    return (
        <div className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5 mt-6">

        <p className="font-aspekta   text-sm inline-flex  py-4">{t("challengeWidget")}</p>

            <button
            onClick={handleButtonClick}
            className="btn-sm w-full text-slate-100 bg-sky-500 hover:bg-sky-600"
        >
            Task Me!
        </button>
        </div>

    );
};

export default TaskMe;
