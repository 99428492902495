import React from 'react';
import {useTranslation} from "react-i18next";
import '../i18n';

const SolutionsWidget = () => {

    const { t, i18n } = useTranslation();

    return (
        <div
            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
            <div className="font-aspekta font-[550] mb-3">{t("optimizedDigitalSolutions")}</div>
            <ul className="space-y-3">
                <li className="inline-flex">
                    <span className="text-sky-500 mr-2">—</span>
                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                        {t("customApplications")}
                    </p>
                </li>
                <li className="inline-flex">
                    <span className="text-sky-500 mr-2">—</span>
                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                        {t("consultingDigital")}
                    </p>
                </li>
                <li className="inline-flex">
                    <span className="text-sky-500 mr-2">—</span>
                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                        {t("integratingEcommerce")}
                    </p>
                </li>
                <li className="inline-flex">
                    <span className="text-sky-500 mr-2">—</span>
                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                        {t("developingWebsites")}
                    </p>
                </li>
                <li className="inline-flex">
                    <span className="text-sky-500 mr-2">—</span>
                    <p className="font-aspekta text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                        {t("providingOngoingSupport")}
                    </p>
                </li>
            </ul>
        </div>

    );
};

export default SolutionsWidget;