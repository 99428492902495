import {useTranslation} from "react-i18next";

const ServicesComponent = () => {
    const {t} = useTranslation();

    const services = [
        "projectManagement",
        "aISolutions",
        "webDevelopment",
        "systemIntegration",
        "digitalTransformation",
        "tailoredSolutions"
    ];

    return (
        <div>
            {services.map((service) => {
                const serviceData = t(service, {returnObjects: true});

                return (
                    <div key={service}>
                        {/* ✅ Bold Title */}
                        <h3 className="my-4">
                            <strong className="font-medium text-slate-800 dark:text-slate-100 my-2">
                                {serviceData.title}
                            </strong>
                        </h3>

                        <ul>
                            {Array.isArray(serviceData.points)
                                ? serviceData.points.map((point, index) => (
                                    <li className="flex items-start mx-2" key={index}>
                                        {/* ✅ SVG Icon */}
                                        <svg
                                            className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                            viewBox="0 0 12 12"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                        </svg>
                                        {/* ✅ Bold Point */}
                                        <span >

                          {point}

                      </span>
                                    </li>
                                ))
                                : null}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default ServicesComponent;
