
import SponsorWidget from "../components/SponsorWidget";
import programmingLanguageFunction from "../hooks/programmingLanguageFunction";
import ProjectCard from '../components/ProjectCard';
import Taskme from "../components/TaskMe";

import { FaLaptopCode } from "react-icons/fa";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaRegHandshake } from "react-icons/fa";
import React, {useEffect} from "react";

import {useTranslation} from "react-i18next";
import '../i18n';
import SolutionsWidget from "../components/SolutionsWidget";


const Projects = () => {

    const { t, i18n } = useTranslation();


    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);

    const sideHustles = [
        {
            title: 'Fundación VOS',
            description: t("projectFundacionVosText"),
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'kids-Foundation',
            tag: 'Non-profit organization',
            link: 'https://fundacionvos.org',
        },
        {
            title: 'Equilibrio App',
            description: t("projectEquilibrioText"),
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 02',
            tag: 'Non-profit organization',
            link: 'https://equilibrio.app',
        },
        // Add more projects here
    ];

    const clientProjects = [
        {
            title: 'Personal Portfolio',
            description: t("projectPortfolioText"),
            imgSrc: <HiOutlineEmojiHappy color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 04',
            tag: 'Personal Development',
            link: '/'
        },
        {
            title: 'MentaPixel',
            description: t("projectMentaPixelText"),
            imgSrc: <FaLaptopCode color="#0ea5e9" size="20px"/>,
            imgAlt: 'LandingPage',
            tag: 'Full-Stack Development Task',
            link: 'https://mentapixel.com'
        }
        // Add more projects here
    ];

    const codeText = [
        `<🚧>${t("projectsConstructionText")}</🚧>`
    ];
    const {openText: animatedCodeText} = programmingLanguageFunction(codeText, 60, 5); // Using hook for animated code text
    const splitTextProjects = animatedCodeText.split(/(<\/?🚧>)/g);

    return (
        <div>
            {/* Content*/}
            <div className="grow md:flex space-y-8 md:space-y-0 md:space-x-8  pb-16 md:pb-20">
                {/* Mitle area*/}
                <div className="grow">
                    <div className="max-w-[700px]">
                        <section>
                            <h1 className="h1 font-aspekta mb-12">{t("generalProjectsSection")}</h1>

                            {/* Animated Code Block */}
                            <pre
                                className="whitespace-pre-wrap text-sm text-black bg-gray-100 my-6 p-4 rounded leading-tight relative">
                                    <code className="font-pt-mono typing ">
                                        {splitTextProjects.map((part, index) => (
                                            <span
                                                key={index}
                                                className={part.includes('<p>') || part.includes('</p>') ? 'text-yellow-400' : ''}
                                            >
                                                {part}
                                            </span>
                                        ))}
                                        <span className="cursor">|</span>
                                    </code>
                                </pre>

                            {/* Client Projects Section */}
                            <section>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {clientProjects.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                            link={project.link}
                                        />
                                    ))}
                                </div>
                            </section>

                            {/* Side Hustles Section */}
                            <section>
                                <h2 className="font-aspekta my-6 text-xl font-[650]  ">{t("ongProjects")}</h2>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {sideHustles.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                            link={project.link}
                                        />
                                    ))}
                                </div>
                            </section>
                        </section>
                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className="md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                       {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <SolutionsWidget />
                        {/* Task widget*/}
                        <Taskme />

                    </div>

                </aside>

            </div>
        </div>

    );
};

export default Projects;
