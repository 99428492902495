import profileImg from '../assets/images/oscarleecorradine.png';
import FaaS from '../assets/images/Freelancer as a Service FaaS.jpeg'

import '../App.css';
import ProgrammingLanguageWidget from '../components/ProgrammingLanguageWidget';
import SponsorWidget from "../components/SponsorWidget";
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import '../i18n';
import programmingLanguageFunction from "../hooks/programmingLanguageFunction";
import SolutionsWidget from "../components/SolutionsWidget";
import ServicesComponent from "../components/ServicesComponent";


const Home = () => {
    const words = ['WORK !', 'HELP !']; // Words to alternate
    const {openText, handleSubmit} = programmingLanguageFunction(words); // Use the custom hook
    const {t, i18n} = useTranslation(); // it could be also {t, i18n}

    const [activeTab, setActiveTab] = useState('carrera'); // Por defecto, muestra "Carrera"

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);

    return (
        <div>
            {/* Content*/}
            <div className="grow md:flex space-y-8 md:space-y-0 md:space-x-8 pb-16 md:pb-20">

                {/* Mitle area*/}
                <div className="grow">
                    <div className="max-w-[700px]">

                        <section className="">
                            {/* Page title*/}
                            <h1 className="h1 font-aspekta mb-5">{t("welcome")}<span
                                className="inline-flex relative text-sky-500 before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:-rotate-2 before:translate-y-1/4">{t("welcomeHighlight")}</span> {t("welcomePort")}
                            </h1>
                            <div className="flex justify-center my-6">
                                <img className=" my-6 rounded-xl shadow-xl "
                                     src={FaaS} alt="About"/>
                            </div>
                            {/* Filters */}
                            <ul className="flex flex-wrap text-sm border-b border-slate-100 dark:border-slate-800">
                                <li className=" pr-3   mb-6">
                                    <button
                                        type="button"
                                        className={`block py-3 text-slate-500 ${activeTab === 'carrera' ? 'active-tab' : ''}`}
                                        onClick={() => setActiveTab('carrera')}
                                    >
                                        {t("homeCareer")}
                                    </button>
                                </li>
                                <li className="px-3 -mb-px">
                                    <button
                                        type="button"
                                        className={`block py-3 text-slate-500 ${activeTab === 'experiencia' ? 'active-tab' : ''}`}
                                        onClick={() => setActiveTab('experiencia')}
                                    >
                                        {t("homeExperience")}
                                    </button>
                                </li>
                                <li className="px-3 -mb-px">
                                    <button
                                        type="button"
                                        className={`block py-3 text-slate-500 ${activeTab === 'servicios' ? 'active-tab' : ''}`}
                                        onClick={() => setActiveTab('servicios')}

                                    >
                                        {t("homeServices")}
                                    </button>
                                </li>
                            </ul>

                            {/*Page Content*/}
                            <div className="text-slate-500 dark:text-slate-400 space-y-8">
                                {activeTab === 'carrera' && (
                                    <>
                                        <div className="space-y-4">
                                            <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">{t("professionalJourneyTitle")}</h2>
                                            <p>
                                                {t("professionalJourneyTextStart")}
                                                <span
                                                    className="font-medium text-sky-500">{t("professionalJourneyHighlightProject")}</span>
                                                {t("professionalJourneyAnd")}
                                                <span
                                                    className="font-medium text-sky-500">{t("professionalJourneyHighlightDtrans")}</span>
                                                {t("professionalJourneyTextEnd1")}
                                            </p>
                                            <p>{t("professionalJourneyTextEnd2")}</p>
                                        </div>
                                        <div className="space-y-4">
                                            <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">{t("globalExperience")}</h2>
                                            <p>{t("globalExperienceText")}</p>
                                        </div>
                                    </>
                                )}

                                {activeTab === 'experiencia' && (
                                    <div className="space-y-8">
                                        <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">{t("recentExperience")}</h2>
                                        {/* Experience Items */}
                                        <ul className="space-y-8">
                                            {/* Item*/}
                                            <li className="relative group">
                                                <div
                                                    className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                    <div
                                                        className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="1.5" stroke="red"
                                                             className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
                                                        </svg>
                                                    </div>
                                                    <div className="pl-20 space-y-1">
                                                        <div className="text-xs text-slate-500 uppercase">Linz <span
                                                            className="text-slate-400 dark:text-slate-600">·</span> Austria
                                                        </div>
                                                        <div
                                                            className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">{t("recentExperienceDevelopment")}
                                                        </div>
                                                        <div
                                                            className="text-sm text-slate-500 dark:text-slate-400">{t("recentExperienceDevelopmentText")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* Item*/}
                                            <li className="relative group">
                                                <div
                                                    className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                    <div
                                                        className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                             className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"/>
                                                        </svg>
                                                    </div>
                                                    <div className="pl-20 space-y-1">
                                                        <div className="text-xs text-slate-500 uppercase">Linz <span
                                                            className="text-slate-400 dark:text-slate-600">·</span> Austria
                                                        </div>
                                                        <div
                                                            className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">{t("recentExperienceOperations")}
                                                        </div>
                                                        <div
                                                            className="text-sm text-slate-500 dark:text-slate-400">{t("recentExperienceOperationsText")}

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* Item*/}
                                            <li className="relative group">
                                                <div
                                                    className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                    <div
                                                        className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00adef"
                                                             className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"/>
                                                        </svg>
                                                    </div>
                                                    <div className="pl-20 space-y-1">
                                                        <div
                                                            className="text-xs text-slate-500 uppercase">Guangzhou <span
                                                            className="text-slate-400 dark:text-slate-600">·</span> Hong
                                                            Kong
                                                        </div>
                                                        <div
                                                            className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">{t("recentExperienceChina")}
                                                        </div>

                                                        <div
                                                            className="text-sm text-slate-500 dark:text-slate-400">{t("recentExperienceChinaText")}

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {activeTab === 'servicios' && (
                                    <div className="space-y-4">
                                        <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">FaaS:
                                            Freelancer as a Service
                                        </h2>
                                        <p>FaaS (Freelancer as a Service) no es una red de freelancers, sino un equipo
                                            de trabajo consolidado con más de cuatro años de experiencia colaborando
                                            juntos. Nuestro modelo se adapta al crecimiento y las necesidades de cada
                                            cliente, ampliando el equipo de forma estratégica según los requerimientos
                                            del proyecto. Mantenemos siempre el control directo tanto del equipo como de
                                            las tareas y proyectos in-house, garantizando una gestión eficiente y sin
                                            recurrir a la subcontratación o externalización.</p>

                                            <ServicesComponent/>

                                        <p>{t("taskFormPromptStart")}<Link to='/taskBoard' className="inline-flex relative text-sky-500 before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:-rotate-2 before:translate-y-1/4">{t("taskFormPromptHighlight")}</Link>{t("taskFormPromptEnd")}</p>

                                    </div>
                                )}
                                <div className="space-y-4">
                                    <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">{t("getInTouch")}</h2>
                                    <p>
                                        <Link to='/contact'
                                              className="font-medium text-sky-500 hover:underline">{t("callTheAttentionContact")} </Link>
                                        {t("getInTouchText")}
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className=" md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit}/>


                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <SolutionsWidget/>

                    </div>
                </aside>

            </div>
        </div>

    );
};

export default Home;
