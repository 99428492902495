import React, {useEffect } from 'react';
import SponsorWidget from "../components/SponsorWidget";
import SolutionsWidget from "../components/SolutionsWidget";

import programmingLanguageFunction from "../hooks/programmingLanguageFunction";
import TaskMe from "../components/TaskMe";
import {useTranslation} from "react-i18next";
import '../i18n';

const About = () => {

    const { t, i18n } = useTranslation();

    const codeText = [
        `<p>${t("letsWorkTogether")}</p>`
    ];
    const {openText: animatedCodeText} = programmingLanguageFunction(codeText, 80, 5); // Using hook for animated code text
    const splitText = animatedCodeText.split(/(<\/?p>)/g);

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);
    return (
        <div>
            <div className="grow md:flex space-y-8  md:space-y-0 md:space-x-8 pb-16 md:pb-20">
                <div className="grow">
                    <div className="max-w-[700px]">
                        <div className="space-y-10">
                            <h1 className="h1 font-aspekta mb-4">{t("keyInterests")}
                            </h1>
                            <div className="text-slate-500 dark:text-slate-400 space-y-8">
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">{t("keyInterestsDevelopmentTitle")}
                                    </h2>
                                    <p>
                                        {t("keyInterestsDevelopmentText")}</p>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">{t("keyInterestsManagementTitle")}
                                    </h2>
                                    <p>
                                        {t("keyInterestsManagementText")}</p>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">{t("keyInterestsEntrepreneurshipTitle")}
                                    </h2>
                                    <p>{t("keyInterestsEntrepreneurshipText")}</p>
                                </div>
                                {/* Animated Code Block */}
                                <pre
                                    className="whitespace-pre-wrap text-sm text-white bg-slate-800 p-4 rounded leading-tight relative">
                                    <code className="font-pt-mono typing ">
                                        {splitText.map((part, index) => (
                                            <span
                                                key={index}
                                                className={part.includes('<p>') || part.includes('</p>') ? 'text-yellow-400' : ''}
                                            >
                                                {part}
                                            </span>
                                        ))}
                                        <span className="cursor">|</span>
                                    </code>
                                </pre>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitle")}</h2>
                                    <p>
                                        {t("keyInterestsDigitalTransformationText")}</p>

                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitles.processDigitalization")}</strong> {t("keyInterestsDigitalTransformation.processDigitalization")}</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitles.cloudAdoption")}</strong> {t("keyInterestsDigitalTransformation.cloudAdoption")}</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitles.integrationDigitalTools")} </strong>{t("keyInterestsDigitalTransformation.integrationDigitalTools")}
                                            </span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitles.cultureOfInnovation")} </strong>{t("keyInterestsDigitalTransformation.cultureOfInnovation")}
                                            </span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">{t("keyInterestsDigitalTransformationTitles.enhancingCustomerExperience")} </strong>{t("keyInterestsDigitalTransformation.enhancingCustomerExperience")}
                                            </span>
                                        </li>

                                    </ul>


                                </div>

                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">{t("keyInterestsLeanTitle")}</h2>
                                    <p>
                                        {t("keyInterestsLeanTextStart")}<strong className="font-medium text-slate-800 dark:text-slate-100"> {t("keyInterestsLeanTextMvp")}</strong>{t("keyInterestsLeanTextStartMeddle")}<strong
                                        className="font-medium text-slate-800 dark:text-slate-100"> {t("keyInterestsLeanStrong")}.</strong> {t("keyInterestsLeanTextEnd")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <aside className="md:w-[240px] lg:w-[300px] shrink-0">
                    <div className="space-y-6">

                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <SolutionsWidget />
                        <TaskMe/>

                    </div>


                        {/* <div
                            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                            <div className="font-aspekta font-[550] mb-3">Languages</div>
                            <ul className="space-y-3">

                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇪🇸</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">Spanish</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>
                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇬🇧</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">English</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_90%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>
                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇩🇪</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">German</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_60%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>

                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇨🇳</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">Chinese</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_50%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>


                            </ul>
                        </div>*/}

                </aside>
            </div>
        </div>
    );
};

export default About;
