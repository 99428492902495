
// src/components/ProgrammingLanguageWidget.js
import { DiPhp } from "react-icons/di";
import { FaAndroid, FaLaravel, FaReact, FaWordpress, FaPython } from 'react-icons/fa';
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import '../i18n';


const ProgrammingLanguageWidget = ({ openText, onContactClick }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleButtonClick = () => {
        navigate('/taskboard'); // Simplemente navega a Taskboard
    };

    return (
        <div className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
            <div className="text-center mb-1">
                <div className="inline-flex -space-x-3 -ml-0.5">
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-[#7377ad] p-1">
                        <DiPhp size={24} className="text-black" /> {/* PHP icon */}
                    </div>
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-black p-1">
                        <FaAndroid size={24} className="text-green-500" /> {/* Android icon */}
                    </div>
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-red-500 p-1">
                        <FaLaravel size={24} className="text-white" /> {/* Laravel icon */}
                    </div>
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-[#22262e] p-1">
                        <FaReact size={24} className="text-blue-500" /> {/* React JS icon */}
                    </div>
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-[#3655e2] p-1">
                        <FaWordpress size={24} className="text-white" /> {/* WordPress icon */}
                    </div>
                    <div className="rounded-full border-2 border-white dark:border-slate-800 box-content bg-[#2e6592] p-1">
                        <FaPython size={24} className="text-[#ffe366]" /> {/* Python icon */}
                    </div>
                </div>
            </div>
            <div className="text-center mb-8">
                <div className="font-aspekta font-[650] mb-1">Ready to <span className="openText">{openText}</span></div>
                <p className="text-sm mt-2.5 text-slate-500 dark:text-slate-400">{t("challengeWidget")}</p>
            </div>
            {/* Botón que adapta su funcionalidad */}
            <button
                onClick={handleButtonClick}
                className="btn-sm w-full text-slate-100 bg-sky-500 hover:bg-sky-600"
            >
                    Task Me!
                </button>
        </div>
    );
};

export default ProgrammingLanguageWidget;
