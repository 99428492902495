// src/components/Footer.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../i18n';

const Footer = () => {
    const location = useLocation();

    const handleContactClick = (event) => {
        if (location.pathname === '/contact') {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <footer className="border-t border-slate-200 dark:border-slate-800 md:ml-24 md:px-8 pb-8 sm:pb-0">
            <div className="py-8">
                <div className="text-center md:flex md:items-center md:justify-between">

                    {/* Social links */}
                    <ul className="inline-flex mb-4 md:order-1 md:ml-4 md:mb-0 space-x-2">
                        <li>
                            <a className="flex justify-center items-center text-sky-500 hover:text-sky-600 transition duration-150 ease-in-out"
                               href="https://www.linkedin.com/in/oscar-lee-corradine-294073191" target="_blank"  rel="noopener noreferrer" aria-label="LinkedIn">
                                <svg className="w-4 h-4 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M27 0H5C2.24 0 0 2.24 0 5v22c0 2.76 2.24 5 5 5h22c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zM10.48 27H5.99V12h4.49v15zM8.24 10.29c-1.44 0-2.61-1.17-2.61-2.61s1.17-2.61 2.61-2.61c1.44 0 2.61 1.17 2.61 2.61s-1.17 2.61-2.61 2.61zm19.26 16.71h-4.49V20.4c0-1.58-.03-3.61-2.2-3.61-2.2 0-2.54 1.72-2.54 3.5v7.71h-4.49V12h4.31v2.05h.06c.6-1.14 2.07-2.34 4.26-2.34 4.56 0 5.4 3 5.4 6.94v8.34z">
                                    </path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                onClick={handleContactClick}
                                className="flex justify-center items-center text-sky-500 hover:text-sky-600 transition duration-150 ease-in-out"
                                aria-label="Custom Icon"
                            >
                                <svg
                                    className="w-4 h-4 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 21 21"
                                >
                                    <path fillOpacity=".16" d="m13.4 18-3-7.4-7.4-3L19 2z" />
                                    <path d="M13.331 15.169 17.37 3.63 5.831 7.669l5.337 2.163 2.163 5.337Zm-3.699-3.801L.17 7.53 20.63.37l-7.161 20.461-3.837-9.463Z" />
                                </svg>
                            </Link>
                        </li>
                    </ul>

                    {/* Copyright */}
                    <div className="text-sm text-slate-500 dark:text-slate-400">&copy; 2025 Oscar Lee Corradine. All rights reserved.
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
