import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationDE from './locales/de/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationEN },
            es: { translation: translationES },
            de: { translation: translationDE },
        },
        fallbackLng: 'en', // Idioma predeterminado si no encuentra otro
        debug: true, // Activa logs en consola para verificar si carga
        interpolation: { escapeValue: false },
    });

export default i18n;
