import React from 'react';
import {useTranslation} from "react-i18next";
import '../i18n';
import {BsTelephone} from "react-icons/bs";
import {GrLanguage} from "react-icons/gr";
import {MdOutlineMarkEmailRead} from "react-icons/md";
import { GiWorld } from "react-icons/gi";

const ContactInfoCard = () => {

    const { t, i18n } = useTranslation();

    return (
        <div
            className="rounded-lg border mt-6 border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
            <div className="font-aspekta font-[550] mb-3">{t("contactInformation")}</div>
            <ul className="space-y-3">

                {/* Email */}
                <li className="flex justify-between items-center">
                    <div className="grow inline-flex mr-1.5 truncate">
                        < MdOutlineMarkEmailRead color="#0ea5e9" size="20px"/>

                        <span
                            className="font-aspekta font-[500] text-sm ml-2">  info@oscarleecorradine.com</span>
                    </div>
                </li>

                {/* Phone */}
                <li className="flex justify-between items-center">
                    <div className="grow inline-flex mr-1.5 truncate">
                        < BsTelephone color="#0ea5e9" size="20px"/>
                        <span className="font-aspekta font-[500] text-sm ml-2"> +43 676 5777 358</span>
                    </div>
                </li>

                {/* Languages I Speak */}
                <li className="flex justify-between items-center">
                    <div className="grow inline-flex mr-1.5 truncate">
                        < GrLanguage color="#0ea5e9" size="20px"/>
                        <span className="font-aspekta font-[500] text-sm ml-2">Spanish, English, German, Chinese</span>
                    </div>
                </li>

                {/* Country */}
                <li className="flex justify-between items-center">
                    <div className="grow inline-flex mr-1.5 truncate">
                        < GiWorld color="#0ea5e9" size="20px"/>
                        <span className="font-aspekta font-[500] text-sm ml-2"> Linz-Austria</span>
                    </div>
                </li>

            </ul>
        </div>
    );
};

export default ContactInfoCard;