import React, { useState, useRef, useEffect } from 'react';
import myImage from '../assets/images/oscarleecorradine-cartoon.png';
import { NavLink } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import '../i18n';

const BurgerMenu = () => {
    const [expanded, setExpanded] = useState(false);
    const menuRef = useRef(null);

    const { t , i18n} = useTranslation(); // it could be also {t, i18n}


    const toggleMenu = () => setExpanded(!expanded);
    const closeMenu = () => setExpanded(false);

    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        if (expanded) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Clean up the event listener on component unmount
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [expanded]);

    return (
        <div ref={menuRef} className="inline-flex sm:hidden">

            {/* Hamburger button */}
            <button
                className={`hamburger ${expanded ? 'active' : ''}`}
                onClick={toggleMenu}
                aria-controls="mobile-nav"
                aria-expanded={expanded}
            >
                <span className="sr-only">Menu</span>
                <svg
                    className="w-8 h-8 fill-current text-white transition duration-150 ease-in-out"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect y="4" width="24" height="2" rx="1" />
                    <rect y="11" width="24" height="2" rx="1" />
                    <rect y="18" width="24" height="2" rx="1" />
                </svg>
            </button>

            {/* Mobile navigation */}
            {expanded && (
                <nav
                    id="mobile-nav"
                    className="fixed top-0 h-screen z-20 left-0 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar"
                >
                    <div className="py-6 pr-4 pl-4">
                        {/* Logo */}
                        <img
                            className="rounded-full mb-5"
                            src={myImage}
                            width="56"
                            height="56"
                            alt="Oscar Lee Corradine"
                        />
                        {/* Links */}
                        <ul className="space-y-4">
                            <li className="py-2">
                                <NavLink
                                    to="/"
                                    onClick={closeMenu}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'text-sky-500 w-full h-6 flex relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:bg-sky-500 border-r-1 border-sky-500'
                                            : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 w-full h-6 flex'
                                    }
                                >
                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="19"
                                    >
                                        <path fillOpacity=".16" d="M4 7v11h13V7l-6.5-5z" />
                                        <path d="m10.433 3.242-8.837 6.56L.404 8.198l10.02-7.44L20.59 8.194l-1.18 1.614-8.977-6.565ZM16 17V9h2v10H3V9h2v8h11Z" />
                                    </svg>
                                    <span className="mx-3">Home</span>
                                </NavLink>
                            </li>
                            <li className="py-2">
                                <NavLink
                                    to="/about"
                                    onClick={closeMenu}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'text-sky-500 w-full h-6 flex border-r-2 border-sky-500'
                                            : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 w-full h-6 flex'
                                    }
                                >
                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                    >
                                        <path fillOpacity=".16" d="M10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z" />
                                        <path d="M9 5h2v2H9V5Zm0 4h2v6H9V9Zm1-9C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z" />
                                    </svg>
                                    <span className="mx-4">Key Interests</span>
                                </NavLink>
                            </li>
                             <li className="py-2">
                                <NavLink
                                    to="/taskboard"
                                    onClick={closeMenu}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'text-sky-500 w-full h-6 flex  border-r-2 border-sky-500'
                                            : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 w-full h-6 flex  '
                                    }
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"/>
                                    </svg>
                                    <span className="  ml-3">Task Board</span>
                                </NavLink>
                            </li>
                            <li className="py-2">
                                <NavLink
                                    to="/projects"
                                    onClick={closeMenu}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'text-sky-500 w-full h-6 flex border-r-2 border-sky-500'
                                            : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 w-full h-6 flex '
                                    }
                                >

                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                    >
                                        <path fillOpacity=".16" d="M1 4h18v10H1z" />
                                        <path d="M8 3h4V2H8v1ZM6 3V0h8v3h6v12H0V3h6ZM2 5v8h16V5H2Zm14 13v-2h2v4H2v-4h2v2h12Z" />
                                    </svg>
                                    <span className="ml-3">Projects</span>
                                </NavLink>
                            </li>
                            <li className="py-2">
                                <NavLink
                                    to="/contact"
                                    onClick={closeMenu}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'text-sky-500 w-full h-6 flex  border-r-2 border-sky-500'
                                            : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 w-full h-6 flex '
                                    }
                                >
                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                    >
                                        <path fillOpacity=".16" d="m13.4 18-3-7.4-7.4-3L19 2z" />
                                        <path d="M13.331 15.169 17.37 3.63 5.831 7.669l5.337 2.163 2.163 5.337Zm-3.699-3.801L.17 7.53 20.63.37l-7.161 20.461-3.837-9.463Z" />
                                    </svg>
                                    <span className=" ml-3">Contact</span>
                                </NavLink>
                            </li>
                            <div className="flex justify-left space-x-[5px] z-50 mt-40">
                                <button
                                    onClick={() => i18n.changeLanguage('es')}
                                    className="languageBtn btn-sm w-auto self-center text-slate-100 bg-sky-500 hover:bg-sky-600 border border-sky-400 px-2 tracking-[0.05em] font-extralight text-sm"
                                >
                                    ES
                                </button>

                                <button
                                    onClick={() => i18n.changeLanguage('en')}
                                    className="languageBtn btn-sm w-auto self-center text-slate-100 bg-sky-500 hover:bg-sky-600  border border-sky-400 px-2 tracking-[0.05em] font-extralight text-sm"
                                >
                                    EN
                                </button>

                                <button
                                    onClick={() => i18n.changeLanguage('de')}
                                    className="languageBtn btn-sm w-auto self-center text-slate-100 bg-sky-500 hover:bg-sky-600 border border-sky-400 px-2 tracking-[0.05em] font-extralight text-sm"
                                >
                                    DE
                                </button>
                            </div>
                        </ul>

                    </div>
                </nav>
            )}
        </div>
    );
};

export default BurgerMenu;